<template>
  <div class="bg-white">
    <div class="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
      <div class="space-y-12">
        <ul
          class="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-2 lg:gap-x-24"
        >
          <li v-for="post in posts" :key="post.id">
            <div class="space-y-4">
              <div class="aspect-w-3 aspect-h-2">
                <img
                  class="object-fit shadow-lg"
                  :src="post.image"
                  :alt="post.title"
                />
              </div>

              <div class="space-y-2 pt-4 pb-8">
                <div class="text-base leading-6 font-light space-y-1">
                  <h3>{{ post.date }}</h3>
                </div>
                <div class="text-xl leading-6 font-medium space-y-1">
                  <h3>{{ post.title }}</h3>
                </div>
                <div class="text-lg leading-6 font-normal space-y-1">
                  <h3>{{ post.brief }}</h3>
                </div>
                <ul class="flex space-x-5">
                  <li>
                    <router-link :to="`/post/${post.id}`" class="m-5 text-blue-400 hover:text-blue-500">
                      <span class="">Read more </span>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </li>

        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Posts",
  components: {},
  data: function() {
    return {
      posts: [
        {
          id: 1,
          title: "What are the ways of hire inside a stafﬁgn agency?",
          brief: 'To explain it in a simple language, there are three possible ways of hire with the stafﬁng agencies. Let’s start with some terms to understand how this works.',
          image:  "/images/blog_1.jpg",
          date: 'March 20, 2021',
        },
        {
          id: 2,
          title: "What are the ways of hire inside a stafﬁgn agency?",
          brief: 'To explain it in a simple language, there are three possible ways of hire with the stafﬁng agencies. Let’s start with some terms to understand how this works.',
          image:  "/images/blog_2.jpg",
          date: 'March 20, 2021',
        },
        {
          id: 3,
          title: "What are the ways of hire inside a stafﬁgn agency?",
          brief: 'To explain it in a simple language, there are three possible ways of hire with the stafﬁng agencies. Let’s start with some terms to understand how this works.',
          image:  "/images/blog_3.jpg",
          date: 'March 20, 2021',
        },
        {
          id: 4,
          title: "What are the ways of hire inside a stafﬁgn agency?",
          brief: 'To explain it in a simple language, there are three possible ways of hire with the stafﬁng agencies. Let’s start with some terms to understand how this works.',
          image:  "/images/blog_4.jpg",
          date: 'March 20, 2021',
        },
      ],
    };
  },
};
</script>

<style scoped></style>
