<template>
  <div class="bg-white">
    <main>
      <posts></posts>
    </main>
  </div>
</template>

<script>
// @ is an alias to /src
import Posts from '../components/blog/Posts.vue';

export default {
  name: 'Blog',
  components: {
    Posts,
  }
}
</script>
